import { LayoutScrollTop } from '@/constants/game'

/**
 * 进入游戏详情
 */
export function getGamesRoutePath(id: number, groupId?: number) {
  LayoutScrollTop()
  const currentGroupId = groupId ? `?groupId=${groupId}` : ''
  return `/recreation/game-details/${id}${currentGroupId}`
}

/**
 * 游戏大厅
 */
export function getGameLobbyRoutePath() {
  return '/recreation'
}

/**
 * 收藏夹
 */
export function getFavoriteRoutePath() {
  return '/recreation/my-collection'
}

/**
 * 近期游戏记录
 */
export function getRecentGamesRoutePath() {
  return '/recreation/recent-previews'
}

/**
 * 我的投注
 */
export function getMyBetsRoutePath() {
  return '/recreation/my-bet'
}

/**
 * 游戏提供商
 */
export function getGameSuppliersRoutePath() {
  return '/recreation/game-provider'
}

/**
 * 游戏分类
 * @param id 游戏分类 id
 * @param name 游戏分类名称
 * @param code 游戏分类 code
 */
export function getGameCategoriesRoutePath({ id, name, code }: { id: number; name: string; code: string }) {
  let url = `/recreation/game-shows`

  if (id) url += `?typeCd=${id}`
  if (name) url += `&title=${name}`
  if (code) url += `&code=${code}`

  return url
}

export function getGameGroupRoutePath({
  id,
  groupId,
  name,
  groupName,
  code,
}: {
  id: number
  groupId: number
  name: string
  groupName: string
  code: string
}) {
  let url = `/recreation/game-shows`

  if (groupId) url += `?groupId=${id || groupId}`
  if (name || groupName) url += `&title=${name || groupName}`
  if (code) url += `&code=${code}`

  return url
}

/**
 * 设置
 */
export function getSettingsRoutePath() {
  return '/recreation/settings'
}

/** 维护页面 */
export function maintenancePath() {
  return '/recreation/maintenance'
}

/** 体育游戏页面 */
export function getSportsRoutePath() {
  return '/recreation/sports'
}

/** 热门游戏 */
export function getPopularGameRoutePath() {
  return '/recreation/popular'
}
